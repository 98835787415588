import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './components/Login';
import BannerList from './components/BannerList';
import AddBanner from './components/AddBanner';
import PrivateRoute from './PrivateRoute';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login onLogin={() => window.location.href = '/banners'} />} />
        <Route
          path="/banners"
          element={
            <PrivateRoute>
              <BannerList />
            </PrivateRoute>
          }
        />
        <Route
          path="/add-banner"
          element={
            <PrivateRoute>
              <AddBanner />
            </PrivateRoute>
          }
        />
        <Route
          path="/add-banner/:id"
          element={
            <PrivateRoute>
              <AddBanner />
            </PrivateRoute>
          }
        />
        <Route path="/" element={<Navigate to="/login" />} />
      </Routes>
    </Router>
  );
};

export default App;
