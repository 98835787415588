import axios from 'axios';

const api = axios.create({
  baseURL: 'https://oli-banners.serveradmin-636.workers.dev', // Replace with your Cloudflare Workers URL
  headers: {
    'Content-Type': 'application/json',
  },
});

export default api;
