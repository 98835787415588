import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function NavBar() {

    const navigate = useNavigate();

    const handleLogout = () => {
        // Clear the token from local storage
        localStorage.removeItem('token');

        // Redirect to the login page
        navigate('/login');
    };

  return (
    <div className="px-4 border-b bg-white">
        <div className="flex h-16 justify-between">
          <div className="flex">
            <div className="flex flex-shrink-0 items-center">
              <img
                alt="OI Banner App"
                src="https://ads-banner.s3.ap-south-1.amazonaws.com/uploads/oi.jpeg"
                className="h-8 w-auto"
              />
            </div>
            <div className=" sm:ml-6 sm:flex sm:space-x-8">
              
              <a
                href="/"
                className="inline-flex items-center border-b-2 border-red-500 px-1 pt-1 text-sm font-medium text-gray-900"
              >
                Home
              </a>
              <span
                onClick={handleLogout}
                className="cursor-pointer inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
              >
                Logout
              </span>
            </div>
          </div>
          <div className="hidden sm:ml-6 sm:flex sm:items-center">
            <button
              type="button"
              className="relative rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              <span className="absolute -inset-1.5" />
              <span className="sr-only">View notifications</span>
              {/* <BellIcon aria-hidden="true" className="h-6 w-6" /> */}
            </button>

            
            <div as="div" className="relative ml-3">
              <div>
                <button className="relative flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                  <span className="absolute -inset-1.5" />
                  <span className="sr-only">Open user menu</span>
                  <img
                    alt=""
                    src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                    className="h-8 w-8 rounded-full"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}
