import React, { useEffect, useState, useCallback } from 'react';
import AWS from 'aws-sdk';
import api from '../api-instance';
import { useNavigate, useParams } from 'react-router-dom';
import NavBar from './NavBar';
import toast, { Toaster } from 'react-hot-toast';

/* 
Sidebanner1 - 1 image
Sidebanner2 - 2 images
ScrollerFixedBanner - 1 image
CubeBanner - 4 images
HeaderBanner : 2 images
*/

let banner_positions = [
  {
    "id": 1,
    "name": "SideBanner1",
    "type": "sidebanner1",
    "images": [
      {
        "id": 1,
        "type": "file",
        "required": false,
        "name": "sidebanner1_img_1",
        "size": {
          "width": "100px",
          "height": "200px"
        },
        "ext": [".png", ".jpg", ".gif"],
        "selected_file": null,
        "uploaded_url": "",
        "input_url": ""
      }
    ]
  },
  {
    "id": 2,
    "name": "SideBanner2",
    "type": "sidebanner2",
    "images": [
      {
        "id": 1,
        "type": "file",
        "required": false,
        "name": "sidebanner2_img_1",
        "size": {
          "width": "100px",
          "height": "200px"
        },
        "ext": [".png", ".jpg", ".gif"],
        "selected_file": null,
        "uploaded_url": "",
        "input_url": "",
        "image_url": ""
      },
      {
        "id": 2,
        "type": "file",
        "required": false,
        "name": "sidebanner2_img_2",
        "size": {
          "width": "100px",
          "height": "200px"
        },
        "ext": [".png", ".jpg", ".gif"],
        "selected_file": null,
        "uploaded_url": "",
        "input_url": "",
        "image_url": ""
      },
      {
        "id": 3,
        "type": "file",
        "required": false,
        "name": "sidebanner2_img_3",
        "size": {
          "width": "100px",
          "height": "200px"
        },
        "ext": [".png", ".jpg", ".gif"],
        "selected_file": null,
        "uploaded_url": "",
        "input_url": "",
        "image_url": ""
      },
      {
        "id": 4,
        "type": "file",
        "required": false,
        "name": "sidebanner2_img_4",
        "size": {
          "width": "100px",
          "height": "200px"
        },
        "ext": [".png", ".jpg", ".gif"],
        "selected_file": null,
        "uploaded_url": "",
        "input_url": "",
        "image_url": ""
      },
      {
        "id": 5,
        "type": "file",
        "required": false,
        "name": "sidebanner2_img_5",
        "size": {
          "width": "100px",
          "height": "200px"
        },
        "ext": [".png", ".jpg", ".gif"],
        "selected_file": null,
        "uploaded_url": "",
        "input_url": "",
        "image_url": ""
      },
      {
        "id": 6,
        "type": "file",
        "required": false,
        "name": "sidebanner2_img_6",
        "size": {
          "width": "100px",
          "height": "200px"
        },
        "ext": [".png", ".jpg", ".gif"],
        "selected_file": null,
        "uploaded_url": "",
        "input_url": "",
        "image_url": ""
      }
    ]
  },
  {
    "id": 3,
    "name": "ScrollerFixedBanner",
    "type": "scrollerfixedbanner",
    "images": [
      {
        "id": 1,
        "type": "file",
        "required": false,
        "name": "scrollerfixedbanner_img_1",
        "size": {
          "width": "100px",
          "height": "200px"
        },
        "ext": [".png", ".jpg", ".gif"],
        "selected_file": null,
        "uploaded_url": "",
        "input_url": "",
        "image_url": ""
      }
    ]
  },
  {
    "id": 4,
    "name": "CubeBanner",
    "type": "cubebanner",
    "images": [
      {
        "id": 1,
        "type": "file",
        "required": false,
        "name": "cubebanner_img_1",
        "size": {
          "width": "100px",
          "height": "200px"
        },
        "ext": [".png", ".jpg", ".gif"],
        "selected_file": null,
        "uploaded_url": "",
        "input_url": "",
        "image_url": ""
      },
      {
        "id": 2,
        "type": "file",
        "required": false,
        "name": "cubebanner_img_2",
        "size": {
          "width": "100px",
          "height": "200px"
        },
        "ext": [".png", ".jpg", ".gif"],
        "selected_file": null,
        "uploaded_url": "",
        "input_url": "",
        "image_url": ""
      },
      {
        "id": 3,
        "type": "file",
        "required": false,
        "name": "cubebanner_img_3",
        "size": {
          "width": "100px",
          "height": "200px"
        },
        "ext": [".png", ".jpg", ".gif"],
        "selected_file": null,
        "uploaded_url": "",
        "input_url": "",
        "image_url": ""
      },
      {
        "id": 4,
        "type": "file",
        "required": false,
        "name": "cubebanner_img_4",
        "size": {
          "width": "100px",
          "height": "200px"
        },
        "ext": [".png", ".jpg", ".gif"],
        "selected_file": null,
        "uploaded_url": "",
        "input_url": "",
        "image_url": ""
      }
    ]
  },
  {
    "id": 5,
    "name": "HeaderBanner",
    "type": "headerbanner",
    "images": [
      {
        "id": 1,
        "type": "file",
        "required": false,
        "name": "headerbanner_img_1",
        "size": {
          "width": "100px",
          "height": "200px"
        },
        "ext": [".png", ".jpg", ".gif"],
        "selected_file": null,
        "uploaded_url": "",
        "input_url": "",
        "image_url": ""
      },
      {
        "id": 2,
        "type": "file",
        "required": false,
        "name": "headerbanner_img_2",
        "size": {
          "width": "100px",
          "height": "200px"
        },
        "ext": [".png", ".jpg", ".gif"],
        "selected_file": null,
        "uploaded_url": "",
        "input_url": "",
        "image_url": ""
      }
    ]
  }
];

const AddBanner = () => {

  // Configure AWS SDK
  const S3_BUCKET = 'ads-banner';
  const REGION = 'ap-south-1';
  const ACCESS_KEY = 'AKIAUA52F4QDOORQKDS7';
  const SECRET_KEY = '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';

  AWS.config.update({
    accessKeyId: ACCESS_KEY,
    secretAccessKey: SECRET_KEY,
    region: REGION,
  });

  const s3 = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  });

  const navigate = useNavigate();
  const { id } = useParams();
  const today = new Date().toISOString().split('T')[0];
  const [formData, setFormData] = useState({
    banner_position: '',
    str_story_id: '',
    str_url: '',
    start_date: '',
    end_date: today,
    is_active: 1,
    geo_location: '',
    creatives: ''
  });

  const [bannerData, setBannerData] = useState({
    id: null,
    name: "",
    type: "",
    images: []
  });

  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const handleImageChange = (e, index) => {
    const file = e.target.files[0];
    if (file) {
      const updatedImages = [...bannerData.images];
      updatedImages[index].selected_file = file;

      const reader = new FileReader();
      reader.onloadend = () => {
        updatedImages[index].previewUrl = reader.result;
        setBannerData(prevData => ({ ...prevData, images: updatedImages }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleInputUrlChange = (e, index) => {
    const { value } = e.target;
    const updatedImages = [...bannerData.images];
    updatedImages[index].input_url = value;
    setBannerData(prevData => ({ ...prevData, images: updatedImages }));
  };

  const handleInputImageUrlChange = (e, index) => {
    const { value } = e.target;
    const updatedImages = [...bannerData.images];
    updatedImages[index].image_url = value;
    setBannerData(prevData => ({ ...prevData, images: updatedImages }));
  };

  const fetchBannerData = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        navigate('/login');
        return;
      }

      const response = await api.post(`/banners-get-single/${id}`, { token });
      setFormData(response.data);
      const creatives = JSON.parse(response.data.creatives);
      setBannerData({
        id: creatives.id,
        name: creatives.name,
        type: creatives.type,
        images: creatives.images.map(img => ({
          ...img,
          selected_file: null,
          previewUrl: img.uploaded_url || '',
        }))
      });
    } catch (err) {
      console.error('Failed to fetch banner data', err);
      navigate('/');
    }
  }, [id, navigate]);

  useEffect(() => {
    if (id) {
      setIsEditing(true);
      fetchBannerData();
    }
  }, [id, fetchBannerData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: name === 'is_active' ? parseInt(value) : value });
  };

  const uploadImageToS3 = async (file) => {
    const params = {
      Body: file,
      Bucket: 'ads-banner',
      Key: `uploads/${file.name}`,
      ContentType: file.type,
    };

    try {
      const data = await s3.upload(params).promise();
      return data.Location;
    } catch (error) {
      console.error('Image upload failed', error);
      throw new Error('Image upload failed');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        navigate('/login');
        return;
      }

      const tempBannerData = { ...bannerData };

      for (let i = 0; i < tempBannerData.images.length; i++) {
        const image = tempBannerData.images[i];
        if (image.selected_file) {
          const uploadedUrl = await uploadImageToS3(image.selected_file);
          image.uploaded_url = uploadedUrl;
        }
      }

      const finalBannerData = {
        ...tempBannerData,
        images: tempBannerData.images.map(image => {
          image.previewUrl = image.uploaded_url || image.previewUrl;
          // image.image_url = image.image_url;
          image.selected_file = null;
          return image;
        })
      };

      const updatedFormData = {
        ...formData,
        creatives: JSON.stringify(finalBannerData),
        banner_position: finalBannerData.name,
        token
      };

      console.log(updatedFormData);
      // return;

      const endpoint = isEditing ? `/banners-update/${id}` : '/banners-save';
      const resp = await api.post(endpoint, updatedFormData);
      
      if (resp.status) {
        const message = isEditing ? 'Banner updated successfully!' : 'Banner added successfully!';
        toast.success(message);
        setFormData({
          banner_position: '',
          str_story_id: '',
          str_url: '',
          start_date: '',
          end_date: today,
          is_active: 1,
          geo_location: '',
          creatives: ''
        });

        setTimeout(() => {
          navigate('/');
        }, 2000);
      } else {
        toast.error("Failed to save banner!");
      }
    } catch (err) {
      toast.error("Failed to add banner");
    } finally {
      setLoading(false);
    }
  };

  const handleBannerPositionChange = (e) => {
    const selectedType = e.target.value;
    const selectedBanner = banner_positions.find(pos => pos.type === selectedType);
    if (selectedBanner) {
      setBannerData({
        id: selectedBanner.id,
        name: selectedBanner.name,
        type: selectedBanner.type,
        images: selectedBanner.images.map((image, index) => ({
          ...image,
          selected_file: null,
          previewUrl: ""
        }))
      });
    }
  };

  return (
    <div>
      <NavBar />
      <div className='mx-auto max-w-xl p-4'>
        <div className="text-center">
          <h2 className="mt-2 text-base font-semibold leading-6 text-gray-900">{isEditing ? 'Edit Banner' : 'Add Banner'}</h2>
          <p className="my-4 text-sm text-gray-500">To enhance your page's visual appeal, you can add a banner...</p>
        </div>
        <form onSubmit={handleSubmit} className="bg-white p-8 rounded-lg shadow-md">
          <div className="grid grid-cols-1 gap-x-6 gap-y-2 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <label htmlFor="banner_position" className="block text-sm font-medium leading-6 text-gray-900">Banner Position</label>
              <select name="banner_position" onChange={handleBannerPositionChange} className="mb-4 w-full p-2 border border-gray-300 rounded" required>
                <option value="">Select a position</option>
                {banner_positions.map(pos => (
                  <option key={pos.id} value={pos.type} selected={pos?.name === formData?.banner_position ? true : false}>{pos.name}</option>
                ))}
              </select>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="str_story_id" className="block text-sm font-medium leading-6 text-gray-900">Story ID</label>
              <input type="text" name="str_story_id" placeholder="Eg. 12345677890,1,234324,3" value={formData.str_story_id} onChange={handleChange} className="mb-4 w-full p-2 border border-gray-300 rounded" />
            </div>

            <div className="sm:col-span-6">
              <label htmlFor="str_url" className="block text-sm font-medium leading-6 text-gray-900">Story URL</label>
              <input type="url" name="str_url" placeholder="Enter URL" value={formData.str_url} onChange={handleChange} className="mb-4 w-full p-2 border border-gray-300 rounded" />
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="start_date" className="block text-sm font-medium leading-6 text-gray-900">Start Date</label>
              <input type="date" name="start_date" value={formData.start_date} onChange={handleChange} className="mb-4 w-full p-2 border border-gray-300 rounded" />
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="end_date" className="block text-sm font-medium leading-6 text-gray-900">End Date</label>
              <input type="date" name="end_date" value={formData.end_date} onChange={handleChange} className="mb-4 w-full p-2 border border-gray-300 rounded" />
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="is_active" className="block text-sm font-medium leading-6 text-gray-900">Is Active</label>
              <select name="is_active" value={formData.is_active} onChange={handleChange} className="mb-4 w-full p-2 border border-gray-300 rounded">
                <option value={1}>Yes</option>
                <option value={0}>No</option>
              </select>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="geo_location" className="block text-sm font-medium leading-6 text-gray-900">Tags (comma separated)</label>
              <input type="text" name="geo_location" placeholder="Enter Geo Location" value={formData.geo_location} onChange={handleChange} className="mb-4 w-full p-2 border border-gray-300 rounded" />
            </div>
          </div>

          {bannerData.images.map((imageData, index) => (
            <div className="mb-4 border border-gray-300 rounded-md p-2" key={index}>
              <div className="grid grid-cols-2">
                <div>
                  <label className="block text-sm font-medium text-gray-700">{imageData.name}</label>
                  {/* required={isEditing ? false : imageData.required} */}
                  { !imageData.image_url && (
                    // <input type="file" accept="image/*" onChange={(e) => handleImageChange(e, index)} className="mt-1 block w-full" required={ imageData.image_url ? false : true } />
                    <input type="file" accept="image/*" onChange={(e) => handleImageChange(e, index)} className="mt-1 block w-full" />
                  )}
                  <input type="url" name={`input_url_${index}`} placeholder={`URL for ${imageData.name}`} value={imageData.input_url} onChange={(e) => handleInputUrlChange(e, index)} className="mb-4 w-full p-2 mt-1 border border-gray-300 rounded" />
                  {/* <input type="url" name={`image_url_${index}`} placeholder={`IMAGE for ${imageData.name}`} value={imageData.image_url} onChange={(e) => handleInputImageUrlChange(e, index)} className="mb-4 w-full p-2 mt-1 border border-gray-300 rounded" required={ imageData.previewUrl ? false : true } /> */}
                  <input type="url" name={`image_url_${index}`} placeholder={`IMAGE for ${imageData.name}`} value={imageData.image_url} onChange={(e) => handleInputImageUrlChange(e, index)} className="mb-4 w-full p-2 mt-1 border border-gray-300 rounded" />
                </div>
                <div className='img'>
                  {(imageData.previewUrl || imageData.image_url) && (
                    <img 
                      src={imageData.previewUrl || imageData.image_url || null} 
                      alt={`Preview of ${imageData.name}`} 
                      className="rounded-md h-20" 
                    />
                  )}
                </div>
              </div>
            </div>
          ))}
          
          <div className="flex justify-end">
            <button type="submit" className="mt-4 w-full sm:w-auto px-4 py-2 bg-blue-600 text-white rounded shadow hover:bg-blue-700" disabled={loading}>
              {isEditing ? 'Update Banner' : 'Add Banner'}
            </button>
          </div>
        </form>
      </div>
      <Toaster />
    </div>
  );
};

export default AddBanner;
