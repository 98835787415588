import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../api-instance';

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  // Redirect to /banners if token is already present
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      navigate('/banners');
    }

    /* const fetchBanners = async () => {
      try {
        const response = await api.post('/test', {
          headers: { Authorization: `${token}` },
        });
        console.log({response})
        // setBanners(response.data.results || []);
        // setBannerList(response.data.results || []);
      } catch (err) {
        console.error('Failed to fetch banners', err);
      } finally {
        // setLoading(false);
      }
    };

    fetchBanners(); */

  }, [navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post('/login', { username, password });
      localStorage.setItem('token', response.data.token);
      onLogin(); // Redirect after successful login
    } catch (err) {
      setError('Invalid username or password');
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-6">
      <h2 className="text-2xl font-bold mb-4">Login</h2>
      <form onSubmit={handleSubmit} className="bg-white p-8 rounded-lg shadow-md w-full max-w-sm">
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          className="mb-4 w-full p-2 border border-gray-300 rounded"
          required
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="mb-4 w-full p-2 border border-gray-300 rounded"
          required
        />
        <button type="submit" className="w-full bg-blue-500 text-white py-2 rounded hover:bg-blue-600">
          Login
        </button>
        {error && <p className="text-red-500 mt-4">{error}</p>}
      </form>
    </div>
  );
};

export default Login;
