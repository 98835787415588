import React, { useEffect, useState, useCallback } from 'react';
import api from '../api-instance';
import { useNavigate, Link } from 'react-router-dom';
import NavBar from './NavBar';
import { Toaster } from 'react-hot-toast';

const BannerList = () => {

  const stats_data = [
    { name: 'Active Banners', stat: '0' },
    { name: 'In-Active Banners', stat: '0' },
    { name: 'All Users (Admin)', stat: '1' }
  ]

  const [banners, setBanners] = useState([]);
  const [loading, setLoading] = useState(true);
  const [bannerList, setBannerList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [tasksPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [stats, setStats] = useState(stats_data);

  const navigate = useNavigate();

  const fetchBanners = useCallback(async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
      return;
    }

    try {
      const response = await api.post('/banners-get', {token: token});
      setBanners(response.data.results || []);
      setBannerList(response.data.results || []);

      const activeCount = response.data.results.filter(banner => banner.is_active).length;
      const inactiveCount = response.data.results.length - activeCount;

      setStats([
        { name: 'Active Banners', stat: String(activeCount) },
        { name: 'Inactive Banners', stat: String(inactiveCount) },
        { name: 'All Users (Admin)', stat: '1' }
      ]);
      
    } catch (err) {
      console.error('Failed to fetch banners', err);
    } finally {
      setLoading(false);
    }
  }, [navigate]);

  useEffect(() => {
    fetchBanners();
  }, [fetchBanners]);

  // const notify = () => toast('Here is your toast.');


  /* const fetchBanners = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
      return;
    }

    try {
      const response = await api.post('/banners-get', {token: token});
      setBanners(response.data.results || []);
      setBannerList(response.data.results || []);
    } catch (err) {
      console.error('Failed to fetch banners', err);
    } finally {
      setLoading(false);
    }
  }; */

  // Search Functionality
  const handleSearch = (e) => {
    const input = e.target.value.toLowerCase();
    setSearchTerm(input);

    const filteredList = banners.filter(banner =>
      banner.banner_position.toLowerCase().includes(input) ||
      banner.str_story_id.toLowerCase().includes(input)
    );
    setBannerList(filteredList);
  };

  // Pagination logic
  const indexOfLastTask = currentPage * tasksPerPage;
  const indexOfFirstTask = indexOfLastTask - tasksPerPage;
  const currentTasks = bannerList.slice(indexOfFirstTask, indexOfLastTask);

  const totalPages = Math.ceil(bannerList.length / tasksPerPage);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleDelete = async (id) => {
    const isConfirmed = window.confirm('Are you sure you want to delete this banner?');
    if (!isConfirmed) {
      return;
    }
  
    try {
      const token = localStorage.getItem('token');
      
      if (!token) {
        navigate('/login');
        return;
      }
    
      const response = await api.post(`/banners-delete/${id}`, { token: token });
      if (response.status) {
        fetchBanners();
      } else {
        alert('Failed to delete banner. Please try again.');
      }
    } catch (err) {
      alert('An error occurred while deleting the banner. Please try again.');
    } finally {
      // setLoading(false);
    }
  };
  

  if (loading) return <p>Loading...</p>;

  return (
    <div className="">
      
      <NavBar />
      
      <div className='p-10'>

        {/* Stats */}
        <div>
          <h3 className="text-base font-semibold leading-6 text-gray-900">All Stats</h3>
          <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
            {stats.map((item) => (
              <div key={item.name} className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                <dt className="truncate text-sm font-medium text-gray-500">{item.name}</dt>
                <dd className="mt-1 text-3xl font-semibold tracking-tight text-red-900">{item.stat}</dd>
              </div>
            ))}
          </dl>
        </div>

        {/* TABLE */}
        <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6 mt-8 rounded-lg">
          <h3 className="text-base font-semibold leading-8 text-red-900 mb-4">All Banners</h3>

          <div className="mb-4 flex justify-between items-center">
            <input
              type="search"
              placeholder="Search..."
              value={searchTerm}
              onChange={handleSearch}
              className="p-2 border border-gray-300 rounded-md"
            />
            <Link
              to="/add-banner"
              className="bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-500"
            >
              Add Banner
            </Link>
            {/* <button onClick={notify}>Make me a toast</button> */}
          </div>
        
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">ID</th>
                  <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Banner Position</th>
                  <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Story ID</th>
                  <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Banner</th>
                  <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Preview</th>
                  <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Start Date</th>
                  <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">End Date</th>
                  <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Status</th>
                  <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Tags</th>
                  <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Story URL</th>
                  <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Action</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {currentTasks.length > 0 ? (
                  currentTasks.map(banner => (
                    <tr key={banner.id}>
                      <td className="px-3 py-4 text-sm text-gray-500">{banner.id}</td>
                      <td className="px-3 py-4 text-sm text-gray-500">{banner.banner_position}</td>
                      <td className="px-3 py-4 text-sm text-gray-500">{banner.str_story_id}</td>
                      <td className="px-3 py-4 text-sm text-gray-500">
                        {/* <img src={banner.str_url} alt="Banner" className="w-32 h-16 object-cover rounded" /> */}
                        {JSON.parse(banner.creatives).images.map((image, index) => (
                          <span><a rel="noreferrer" className='btn button text-red-500' href={image.uploaded_url} target='_blank'>Show Banner {index+1} <br/></a></span>
                        ))}
                      </td>
                      <td className="px-3 py-4 text-sm text-gray-500">
                        {JSON.parse(banner.creatives).images.map((image, index) => (
                          <img
                              src={image.uploaded_url || image.image_url}
                              alt=""
                              className="hover-zoom w-20"
                          />
                        ))}
                      </td>
                      <td className="px-3 py-4 text-sm text-gray-500">{banner.start_date}</td>
                      <td className="px-3 py-4 text-sm text-gray-500">{banner.end_date}</td>
                      <td className="px-3 py-4 text-sm text-gray-500">{banner.is_active === 1 ? 'Active' : 'In-Active'}</td>
                      <td className="px-3 py-4 text-sm text-gray-500">{banner.geo_location}</td>
                      <td className="px-3 py-4 text-sm text-gray-500">
                        <a rel="noreferrer" href={banner.str_url} target='_blank'>Open URL</a>
                      </td>
                      <td className="px-3 py-4 text-sm font-medium">
                        <Link
                          to={`/add-banner/${banner.id}`}
                          className="text-indigo-600 hover:text-indigo-900 mr-4"
                        >
                          Edit
                        </Link>
                        <button
                          onClick={() => handleDelete(banner.id)}
                          className="text-red-600 hover:text-red-900"
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5" className="px-3 py-4 text-sm text-gray-500 text-center">No data found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {/* Pagination */}
          <nav className="flex items-center justify-between mt-6">
            <button
              onClick={() => paginate(currentPage > 1 ? currentPage - 1 : 1)}
              disabled={currentPage === 1}
              className="px-4 py-2 bg-gray-200 text-gray-600 rounded-md disabled:opacity-50"
            >
              Previous
            </button>
            <div className="flex space-x-2">
              {[...Array(totalPages).keys()].map(page => (
                <button
                  key={page + 1}
                  onClick={() => paginate(page + 1)}
                  className={`px-4 py-2 rounded-md ${
                    page + 1 === currentPage
                      ? 'bg-indigo-600 text-white'
                      : 'bg-gray-200 text-gray-600'
                  }`}
                >
                  {page + 1}
                </button>
              ))}
            </div>
            <button
              onClick={() => paginate(currentPage < totalPages ? currentPage + 1 : totalPages)}
              disabled={currentPage === totalPages}
              className="px-4 py-2 bg-gray-200 text-gray-600 rounded-md disabled:opacity-50"
            >
              Next
            </button>
          </nav>
        </div>

      </div>

      <Toaster />
    </div>
  );
};

export default BannerList;
